<template>
  <page-view bgColor="#ffffff">
    <template v-slot:nav>
      <nav-bar title="店铺信息" :leftArrow="false"></nav-bar>
    </template>
    <div style="position:relative;padding-bottom:125px;">
      <div class="layout">
        <span class="logo">
          <img src="@/assets/images/static/newlogo.png" alt="">
        </span>
        <span class="tips">为了更好的为您服务 请您根据实际情况选择</span>
        <span class="storename" v-if="!storeDisabled">
          <svg class="icon" aria-hidden="true"><use xlink:href="#icon-dianpu-dianji"></use></svg>
          <van-field v-model="storeName" placeholder="请输入您的店铺名称" border style="border-bottom:1px solid #c8c8c8;width:80%;"></van-field>
        </span>
        <div class="choose__storetip">
          <span>请选择门店类型</span>
          <span style="font-size:12px;">不同行业功能有所差异</span>
        </div>
        <div class="choose-state">
          <span class="choose__item" :class="item.checked ? 'active' : ''" v-for="(item, index) in versionList" :key="index" @click="clickItem(item, 'version')">{{ item.text }}</span>
        </div>
        <!-- <div>需要解决的问题,可多选</div> -->
        <div class="choose__question">
          <span>您想解决哪些问题</span>
        </div>
        <div class="choose-substate">
          <span class="choose__item" :class="item.checked ? 'active' : ''" v-for="(item, index) in subVersion" :key="index" @click="clickItem(item, 'subversion')">{{ item.text }}</span>
        </div>
        <div class="choose__question">
          <span>您的开店时间</span>
        </div>
        <div class="choose-substate">
          <span class="choose__item" :class="item.checked ? 'active' : ''" v-for="(item, index) in timeList" :key="index" @click="clickItem(item, 'time')">{{ item.time }}</span>
        </div>
        <span class="bottom__btn" :class="Choices.length < 2 ? 'disable' : ''" @click="submitInfo">开启好生意</span>
      </div>
    </div>
    <!-- </c-content> -->
    <!-- 数字键盘 -->
    <!-- 地址选择 -->
  </page-view>
</template>
<script>
import NavBar from '@/components/nav-bar'
import PageView from '@/layouts/PageView'
import LoginModel from '@/model/login'
import { Popup, Picker, Tab, Tabs, Cell, CellGroup, Button, Field, Notify } from 'vant'
export default {
  name: '',
  mixins: [],
  components: { [NavBar.name]: NavBar, [PageView.name]: PageView, [Popup.name]: Popup, [Picker.name]: Picker, [Tab.name]: Tab, [Tabs.name]: Tabs, [Cell.name]: Cell, [CellGroup.name]: CellGroup, [Button.name]: Button, [Field.name]: Field, [Notify.name]: Notify },
  props: {},
  data () {
    return {
      storeName: '',
      versionList: [],
      timeList: [],
      subVersion: [],
      Choices: [],
      ShopAge: '',
      storeDisabled: false
    }
  },
  watch: {
  },
  async created () {
  },
  mounted () {
    this.getBaseInfo()
    this.getIfStoreName()
  },
  methods: {
    async getIfStoreName () {
      const data = await LoginModel.getStoreName()
      this.storeDisabled = data.data
    },
    async getBaseInfo () {
      const data = await LoginModel.getBaseInfo()
      this.timeList = this.formateJSON(data.data.shopAge, 'time')
      this.versionList = this.formateJSON(data.data.list, 'version')
    },
    formateJSON (val, type) {
      if (type === 'version') {
        val.forEach(item => {
          item.checked = false
          item.children.forEach(item2 => {
            item2.checked = false
          })
        })
        val[0].checked = true
        this.subVersion = val[0].children
        this.Choices.push(val[0].id)
        return val
      }
      const arr = []
      val.forEach(item => {
        arr.push({
          time: item,
          checked: false
        })
      })
      return arr
    },
    unique (arr) { // 去重
      if (!Array.isArray(arr)) {
        return
      }
      const res = []
      for (let i = 0; i < arr.length; i++) {
        if (res.indexOf(arr[i]) === -1) {
          res.push(arr[i])
        }
      }
      return res
    },
    async submitInfo () {
      if (this.Choices.length < 2) return
      // window.$bridge.page.homePage()
      if (this.storeDisabled) {
        const Choices = this.unique(this.Choices)
        const ShopAge = this.ShopAge || '未知'
        const params = {
          ShopAge,
          Choices
        }
        const data = await LoginModel.submitInfo(params)
        // window.$bridge.page.homePage()
        if (data.data === -2) {
          Notify({ type: 'warning', message: '信息已设置' })
          window.$bridge.page.homePage()
        // TODO
        }
        if (data.data === -1) {
          Notify({ type: 'warning', message: '请填写完整信息' })
          window.$bridge.page.homePage()
        // TODO
        }
        if (data.code === 0) {
          Notify({ type: 'success', message: '信息设置成功' })
          window.$bridge.page.homePage()
        // TODO
        }
        return
      }
      if (!this.storeName) {
        Notify({ type: 'danger', message: '请输入店铺名称' })
        return
      }
      const storeData = await LoginModel.changeStoreName(this.storeName)
      if (storeData.code === 0) {
        const Choices = this.unique(this.Choices)
        const ShopAge = this.ShopAge || '未知'
        const params = {
          ShopAge,
          Choices
        }
        const data = await LoginModel.submitInfo(params)
        if (data.data === -2) {
          Notify({ type: 'warning', message: '信息已设置' })
          window.$bridge.page.homePage()
        // TODO
        }
        if (data.data === -1) {
          Notify({ type: 'warning', message: '请填写完整信息' })
          window.$bridge.page.homePage()
        // TODO
        }
        if (data.code === 0) {
          Notify({ type: 'success', message: '信息设置成功' })
          window.$bridge.page.homePage()
        // TODO
        }
        return
      }
      if (storeData.data === 1) {
        Notify({ type: 'danger', message: '店铺名称长度不正确' })
        return
      }
      if (storeData.data === 2) {
        Notify({ type: 'danger', message: '店铺名称不可全部为数字' })
        return
      }
      if (storeData.data === 3) {
        Notify({ type: 'danger', message: '店铺名称不可全部为符号' })
      }
      // if (storeData)
    },
    clickItem (val, type) {
      if (type === 'version') {
        if (!this.Choices.includes(val.id)) {
          val.children.forEach(item => {
            item.checked = false
          })
          this.Choices = []
        }
        this.versionList.forEach((item, index) => {
          item.checked = false
          this.Choices = this.Choices.filter(item2 => item.id !== item2)
        })
        val.checked = true
        this.subVersion = val.children
        this.Choices.push(val.id)
        return
      }
      if (type === 'time') {
        this.timeList.forEach(item => { item.checked = false })
        val.checked = true
        this.ShopAge = ''
        this.ShopAge = val.time
        return
      }
      if (val.checked) {
        this.Choices = this.Choices.filter(item => item !== val.id)
        val.checked = !val.checked
        return
      }
      this.Choices.push(val.id)
      val.checked = !val.checked
    }
  },
  computed: {
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
