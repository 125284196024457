import api from '@/tools/api-tools'
import { apiUrl } from '@/config/net'

export default class LoginModel {
  /**
   * 获取version 信息
   */
  static getBaseInfo () {
    return api.get(`${apiUrl}/reg/survey/choice/list`).then(res => res.data)
  }

  /**
   * 提交信息
   */
  static submitInfo (params) {
    return api.post(`${apiUrl}/reg/survey/submit`, params).then(res => res.data)
  }

  /**
   * 检测是否已经输入过店铺名称
   */
  static getStoreName () {
    return api.get(`${apiUrl}/reg/survey/name/status`).then(res => res.data)
  }

  /**
   * 修改店铺名称
   */
  static changeStoreName (storeName) {
    return api.post(`${apiUrl}/reg/survey/name/update?newName=${storeName}`).then(res => res.data)
  }
}
